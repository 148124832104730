import { useMemo } from 'react'

import { getBrand } from 'pared/utils/brand'

export interface IChatBoxConfig {
  hasCannedQuestions: boolean
  hasSalesAnalysisButton?: boolean
  hasQuestionMenu: boolean
  hasPlv: boolean
  plvDisplayString: string
  hasCogs: boolean
  directorDisplayString: string
  hideDriveThru?: boolean
  hidePmix?: boolean
  hideGuest?: boolean
  hideBuns?: boolean
  hideSos?: boolean
  showChicken?: boolean
}

export interface IExpoAiBrandConfig {
  chatBox: IChatBoxConfig
  showExray: boolean
  showChatBox: boolean
  usePurchasesPage?: boolean
}

export default function useConfig(): IExpoAiBrandConfig {
  const brand = getBrand()

  return useMemo(() => {
    switch (brand) {
      case 'demo_bk':
        return {
          chatBox: {
            hasCannedQuestions: false,
            hasQuestionMenu: true,
            hasPlv: true,
            plvDisplayString: 'PLV',
            hasCogs: false,
            directorDisplayString: 'Region',
          },
          showExray: true,
          showChatBox: true,
        }
      case 'supreme_bk':
      case 'supreme_pop':
      case 'ace_pop':
        return {
          chatBox: {
            hasCannedQuestions: false,
            hasQuestionMenu: false,
            hasPlv: true,
            plvDisplayString: 'PLV',
            hasCogs: false,
            directorDisplayString: 'Director',
          },
          showExray: true,
          showChatBox: true,
        }

      case 'mbn_bk':
      case 'irmg_bk':
      case 'timoney_bk':
        return {
          chatBox: {
            hasCannedQuestions: false,
            hasQuestionMenu: false,
            hasPlv: true,
            plvDisplayString: 'PLV',
            hasCogs: false,
            directorDisplayString: 'Region',
          },
          showExray: true,
          showChatBox: true,
        }
      case 'jsc_bk':
      case 'jsc_bk_nso':
        return {
          chatBox: {
            hasCannedQuestions: false,
            hasQuestionMenu: false,
            hasPlv: true,
            plvDisplayString: 'AvT',
            hasCogs: false,
            directorDisplayString: 'District Leader',
          },
          showExray: true,
          showChatBox: true,
        }

      case 'ghai_pop':
        return {
          chatBox: {
            hasCannedQuestions: false,
            hasSalesAnalysisButton: true,
            hasQuestionMenu: false,
            hasPlv: false,
            plvDisplayString: '',
            hasCogs: true,
            directorDisplayString: 'District Leader',
          },
          showExray: true,
          showChatBox: true,
        }

      case 'wingitnorth':
        return {
          chatBox: {
            hasCannedQuestions: false,
            hasQuestionMenu: true,
            hasPlv: false,
            plvDisplayString: 'AvT',
            hasCogs: true,
            directorDisplayString: 'Director',
            hideDriveThru: true,
            hidePmix: true,
            hideGuest: true,
            hideBuns: true,
            showChicken: true,
            hideSos: true,
          },
          showExray: true,
          showChatBox: true,
          usePurchasesPage: true,
        }
      case 'primanti':
        return {
          chatBox: {
            hasCannedQuestions: false,
            hasQuestionMenu: false,
            hasPlv: false,
            plvDisplayString: 'PLV',
            hasCogs: false,
            directorDisplayString: 'Director',
          },
          showExray: false,
          showChatBox: true,
        }
      case 'mwb':
        return {
          chatBox: {
            hasCannedQuestions: false,
            hasSalesAnalysisButton: true,
            hasQuestionMenu: false,
            hasPlv: false,
            plvDisplayString: 'AvT',
            hasCogs: true,
            directorDisplayString: 'Director',
          },
          showExray: true,
          showChatBox: true,
        }
      case 'burgerworks':
        return {
          chatBox: {
            hasCannedQuestions: false,
            hasQuestionMenu: false,
            hasPlv: false,
            plvDisplayString: 'AvT',
            hasCogs: true,
            directorDisplayString: 'Director',
          },
          showExray: true,
          showChatBox: true,
        }

      case 'bibibop':
        return {
          chatBox: {
            hasCannedQuestions: false,
            hasSalesAnalysisButton: true,
            hasQuestionMenu: false,
            hasPlv: false,
            plvDisplayString: 'PLV',
            hasCogs: false,
            directorDisplayString: 'District Manager',
          },
          showExray: true,
          showChatBox: true,
        }

      default:
        return {
          chatBox: {
            hasCannedQuestions: true,
            hasQuestionMenu: false,
            hasPlv: false,
            plvDisplayString: 'PLV',
            hasCogs: true,
            directorDisplayString: 'Director',
          },
          showExray: false,
          showChatBox: true,
        }
    }
  }, [brand])
}
